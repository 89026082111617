const leaflets = {
    "massport": {
        "config": {
            "primary-color": "#000000",
            "secondary-color": "#cdd100",
            "tertiary-color": "#ffdd00",
            "hover-color": "rgba(220, 35, 17, 0.2)",
            "clientIcon": require("assets/images/mass_clientLogo.png"),
            "serverPath": "massport.volantinointerattivo.net/",
            "pdfPath": "/media/85xtoJIJQN/pdf/volantino.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo volantino di Mass Sport!",
            "shareFlyerURL": "https://massport.volantinointerattivo.net/leaflet",
            "shareProductSentence": "Guarda questa fantastica offerta di Mass Sport!",
            "ogTitleMeta": "Volantino Interattivo Mass Sport",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo volantino.",
            "ogImageMeta": "https://massport.volantinointerattivo.net/media/85xtoJIJQN/images/share_facebook.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": "",
            "ga_active": true,
            "ga_tracking_id": "UA-152069090-12",
            "release_id": "1",
        },
        "leaflet": {
            "name": "Mass_Sport",
            "code": "9708",
            "id_campaign": "massport_02/20",
        
            "index": {
                "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/pages/indice_massport_0aDQKZ8.png",
                "links": [
                    {
                        "page": 0,
                        "blueprint": {
                            "top": 28.47573389124142,
                            "left": 6.862833763996553,
                            "width": 81.61525624461672,
                            "height": 5.7746304087316265
                        }
                    },
                    {
                        "page": 2,
                        "blueprint": {
                            "top": 38.61785275429052,
                            "left": 6.954188199827732,
                            "width": 81.61525624461672,
                            "height": 5.7746304087316265
                        }
                    }
                ]
            },
            "categories": [
                {
                    "category_name": "Abbigliamento",
                    "subcategories": [
                        "Fitness e Palestra",
                        "Felpe e Pantaloni Lunghi",
                        "T-Shirt, Polo e Bermuda",
                        "Giacconi, K-Way e Piumini"
                    ]
                },
                {
                    "category_name": "Borse e Accessori",
                    "subcategories": [
                        "Borse",
                        "Accessori",
                        "Palloni"
                    ]
                },
                {
                    "category_name": "Kit Gara",
                    "subcategories": [
                        "Kit Calcio"
                    ]
                },
            ],
            "pages": [
                {
                    "number": 1,
                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/pages/page1_8FBbtl9.jpg",
                    "products": [
                        {
                            "item_id": "cstm79528689",
                            "field1": "KIT SIVIGLIA",
                            "field2": "kit gara bicolore",
                            "field3": "con inserti traspiranti",
                            "field4": "",
                            "description": "KIT SIVIGLIA kit gara bicolore con inserti traspiranti",
                            "grammage": null,
                            "price": "8.70",
                            "price_label": "€ 8,70",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Kit Gara",
                            "subcategory": "Kit Calcio",
                            "blueprint": {
                                "top": 20.01320159149982,
                                "left": 54.82391257536606,
                                "width": 43.15503875968992,
                                "height": 33.100211804080466
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/Kit_siviglia_WxmEZiX.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/WhatsApp_Image_2020-02-14_at_12.17.23_WKqZJhJ.jpeg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_qou7sQ4_RTY1o08.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "KIT SIVIGLIA",
                                    "data": "https://www.massport.it/it/sport/calcio/kit-da-calcio-siviglia?search_query=kit+siviglia&results=1#/10-misura-xs/58-colore-bianco_nero"
                                },
                                {
                                    "type": "info",
                                    "title": "KIT DA CALCIO SIVIGLIA",
                                    "data": {
                                        "titolo": "",
                                        "testo": "Il Kit da calcio Siviglia è il capo ideale per comporre la divisa di ogni calciatore, che dev'essere pratica e traspirante, leggera ma al contempo resistente. Caratteristiche, queste, ottenute grazie alla composizione in poliestere fresco con elastam, un tessuto ultramorbido dalla sorprendente freschezza, leggerezza e robustezza. Questo materiale assicura al prodotto la massima resistenza durante le gare e l'allenamento nonché ai frequenti lavaggi in lavatrice.\n\nGrazie agli inserti in mesh sotto le braccia, alle cuciture antisfregamento e al taglio giromanica, questo capo è in grado di assicurare perfetta vestibilità (slim fit), traspirabilità e massimo comfort all’atleta di qualsiasi età (adulto / ragazzo / bambino) e categoria.\n\nIl kit Siviglia è caratterizzata da uno stile moderno e fresco, si presenta a tinta unita con logo ricamato e dettagli a contrasto.",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/Kit_siviglia.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm80582157",
                            "field1": "MAGLIA BLOCK",
                            "field2": "maglia portiere",
                            "field3": "",
                            "field4": "",
                            "description": "MAGLIA BLOCK maglia portiere",
                            "grammage": null,
                            "price": "13.00",
                            "price_label": "€ 13,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Kit Gara",
                            "subcategory": "Kit Calcio",
                            "blueprint": {
                                "top": 53.281935441629024,
                                "left": 4.487618432385871,
                                "width": 42.7896210163652,
                                "height": 23.281090357052044
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/Magliablockrosa_YXLyavP.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_KblHzLV_Fkv2aYa.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "MAGLIA BLOCK DA PORTIERE",
                                    "data": "https://www.massport.it/it/sport/portiere/maglia-block-da-portiere?search_query=maglia+block&results=1#/6-misura-l/107-colore-rosa_grigio"
                                },
                                {
                                    "type": "info",
                                    "title": "MAGLIA BLOCK DA PORTIERE",
                                    "data": {
                                        "titolo": "",
                                        "testo": "Maglia da portiere dalla vestibilità slim e dal carattere aggressivo, realizzata in poliestere ultra resistente, in grado di assorbire urti su qualsiasi tipo di terreno e di sostenere anche le parate più difficili. Il tessuto di questa maglia è un materiale di ultima generazione, liscio e fresco al contatto con la pelle, che non si attacca al corpo neppure durante le fasi di intensa sudorazione. L'elevata traspirabilità è frutto anche degli inserti in mesh traforato e delle cuciture interne anti attrito e antisfregamento.\n\nLe imbottiture di protezione in spugna con maggiore spessore sui gomiti, riescono a trasmettere sicurezza anche a chi è alle prime armi. Questo capo, infatti, può essere indossato sia da adulti che da bambini. Il giromanica di questa maglietta da portiere è studiato per donare il massimo comfort e libertà di movimento.\n\nResistente ai lavaggi frequenti in lavatrice: non stinge e non perde in elasticità né in vestibilità.",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/Magliablockrosa.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm21888803",
                            "field1": "BORSA LINE",
                            "field2": "borsa grande con fondo rigido incluso",
                            "field3": "bicolore e con doppia zip laterale",
                            "field4": "cm 54 x 32 x 52h",
                            "description": "BORSA LINE borsa grande con fondo rigido incluso bicolore e con doppia zip laterale cm 54 x 32 x 52h",
                            "grammage": null,
                            "price": "22.50",
                            "price_label": "€ 22,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Blu/Rosso",
                                "Blu/Granata",
                                "Nero/Azzurro"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Borse e Accessori",
                            "subcategory": "Borse",
                            "blueprint": {
                                "top": 52.5067416431794,
                                "left": 46.70461886304909,
                                "width": 50.920165805340226,
                                "height": 25.671271235605015
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/Borsa_lineblurosso_2yEQWYm.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_J6tIgRu_nrDjt5r.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "BORSA DA ALLENAMENTO / PALESTRA LINE",
                                    "data": "https://www.massport.it/it/prodotti/borse-e-accessori/borse/borsa-da-allenamento-palestra-line#/2-misura-unica/28-colore-blu_rosso"
                                },
                                {
                                    "type": "info",
                                    "title": "BORSA DA ALLENAMENTO / PALESTRA LINE",
                                    "data": {
                                        "titolo": "",
                                        "testo": "La Borsa Line è una borsa da gara di altissima qualità e classe, pensata per accompagnare l’atleta in occasione delle competizioni sportive. L’accessorio è realizzato in poliestere 600, che offre maggiore resistenza anche ai carichi più pesanti e protezione dagli agenti atmosferici, grazie alla spalmatura interna, appositamente studiata per poter riporre con massima sicurezza indumenti ed oggetti negli scompartimenti.\n\nIl portascarpe rigido, realizzato in Italia, si integra perfettamente alla borsa, garantendo assoluta solidità. Le cuciture rinforzate per resistere a sollecitazioni e peso elevati e le zip maggiorate per facilitare la chiusura testimoniano l’esperienza dell’azienda nella cura dei dettagli, mentre le tasche laterali garantiscono maggiore capienza e comodità. Con loghi ricamati, piping a contrasto e design bicolore questa borsa permette di distinguersi, con uno stile elegante ed alternativo.",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/Borsa_lineblurosso.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm35705977",
                            "field1": "PALLONE HYDRA",
                            "field2": "pallone da allenamento / gara",
                            "field3": "con cuciture a mano",
                            "field4": "",
                            "description": "PALLONE HYDRA pallone da allenamento / gara con cuciture a mano",
                            "grammage": null,
                            "price": "6.50",
                            "price_label": "€ 6,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Borse e Accessori",
                            "subcategory": "Palloni",
                            "blueprint": {
                                "top": 76.79614732793392,
                                "left": 2.10115202411714,
                                "width": 44.434000861326446,
                                "height": 17.337937902271683
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/Palloni_hydra_aQUSOCj.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_vZsh8jJ_CmjWNn6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "PALLONE CALCIO HYDRA",
                                    "data": "https://www.massport.it/it/prodotti/borse-e-accessori/palloni/pallone-calcio-hydra?search_query=pallone+mass&results=5#/41-colore-bianco_perlato_nero/39-misura-5"
                                },
                                {
                                    "type": "info",
                                    "title": "PALLONE CALCIO HYDRA",
                                    "data": {
                                        "titolo": "",
                                        "testo": "Il pallone Hydra è un pallone da calcio ideato per ottimizzare le performance sportive in ogni condizione e su ogni terreno di gioco. Realizzato in TPU (poliuretano termoplastico) da 4 mm che dona alla sfera massima resistenza, mantenendo la sua morbidezza anche con condizioni di clima rigido e dopo innumerevoli partite o allenamenti.\n\nLa camera d’aria in butile garantisce rimbalzi regolari e precisione di tiro, permettendo all’atleta di ottenere il miglior rendimento possibile in qualsiasi condizione. Il design con colori a contrasto, nel classico bianco perlato e nero, è studiato per dare una maggiore visibilità alla sfera. Ideale sia per la gara che per l'allenamento.",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/Palloni_hydra.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm94123022",
                            "field1": "PALLONE SIRIUS",
                            "field2": "pallone gara con tocco morbido",
                            "field3": "con cuciture a mano",
                            "field4": "",
                            "description": "PALLONE SIRIUS pallone gara con tocco morbido con cuciture a mano",
                            "grammage": null,
                            "price": "14.00",
                            "price_label": "€ 14,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Borse e Accessori",
                            "subcategory": "Palloni",
                            "blueprint": {
                                "top": 76.79614732793392,
                                "left": 48.53170757967269,
                                "width": 51.46829242032731,
                                "height": 17.337937902271683
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/Palloni_Sirius_k7sRpjh.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_E748IxF_7W0HoNH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "PALLONE SIRIUS",
                                    "data": "https://www.massport.it/it/prodotti/borse-e-accessori/palloni/pallone-sirius?search_query=pallone+&results=5#/37-misura-4/15-colore-azzurro"
                                },
                                {
                                    "type": "info",
                                    "title": "PALLONE SIRIUS",
                                    "data": {
                                        "titolo": "",
                                        "testo": "Il pallone Sirius rappresenta  l'evoluzione del pallone da calcio da gara e da allenamento, rispetto agli anni precedenti. Gli innovativi 4 strati che lo compongono, la nuova valvola di gonfiaggio,  la camera d'aria in butile, la superficie in PU e le cuciture a mano sono state ideate per ottimizzare rimbalzi e precisione di tiro, generando feeling in ogni condizione di gioco aumentandone, ancor di più,  la resistenza.\nL’interno in schiuma favorisce l'adattamento su ogni superficie garantendo al tempo stesso massimo controllo e tocco morbido anche in condizioni di meteo avverse mentre il design con colori a contrasto conferisce la massima visibilità anche su campi poco illuminati.",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/Palloni_Sirius.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "specs",
                                    "title": "PALLONE SIRIUS | Caratteristiche",
                                    "data": {
                                        "specifiche": "Peso: 380gr (mis. 4) / 430gr (mis. 5), Circonferenza: 67cm (mis. 4) / 69cm (mis. 5), Durata media: 12 mesi, Gonfiaggio consigliato: max 0.6 bar, Rivestito in PU_per preservare immutate le caratteristiche del pallone, Camera d'aria in butile  per favorire rimbalzi regolari, Nuova valvola di gonfiaggio per evitare la perdita di pressione improvvisa, Cuciture a mano per aumentare la resistenza all’usura, Interno in schiuma per permettere il tocco morbido in qualsiasi condizione",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/Palloni_Sirius_p6uqakJ.jpg"
                                        ]
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 2,
                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/pages/page2_7yqynJa.jpg",
                    "products": [
                        {
                            "item_id": "cstm03372189",
                            "field1": "PANTA USA ",
                            "field2": "pantalone tempo libero con zip",
                            "field3": "ed elastici alle caviglie e con banda laterale",
                            "field4": "",
                            "description": "PANTA USA  pantalone tempo libero con zip ed elastici alle caviglie e con banda laterale",
                            "grammage": null,
                            "price": "17.00",
                            "price_label": "€ 17,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Felpe e Pantaloni Lunghi",
                            "blueprint": {
                                "top": 4.767723555324108,
                                "left": 2.2150086132644224,
                                "width": 28.721037898363484,
                                "height": 32.906413354468064
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/PANTA_USA_VaXu66A.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_KAsGU8R_r6jByWi.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "PANTALONE USA",
                                    "data": "https://www.massport.it/it/prodotti/abbigliamento/tute-rappresentanza/pantalone-usa#/1-colore-blu/12-misura-xxs"
                                },
                                {
                                    "type": "info",
                                    "title": "PANTALONE USA",
                                    "data": {
                                        "titolo": "",
                                        "testo": "Il pantalone Usa rappresenta un salto di qualità rispetto al passate in termini di comfort, vestibilità e stile. Studiato per chi desidera indossarlo in qualsiasi momento della giornata senza essere mai fuori luogo. Il nuovo tessuto in poliestere leggermente felpato, offre protezione dal freddo sia durante l’attività sportiva che nei momenti di relax e tempo libero. Il risultato?  Un pantalone utilizzabile anche nei periodi primaverili, autunnali ed invernali.\n\nIl taglio moderno , la vestibilità comoda,   le costine elasticizzate in vita e gli elastici alle caviglie, lo rendono estremamente adattabile  comodo da indossare.\n\nLe chiusura con zip alle caviglie in nylon, resistente e robusta,  rende questo pantalone  ancora più pratico da usare. Il design sobrio con finiture tono su tono e bande laterali creano uno look contemporaneo e inconfondibile.  Disponibile in taglie adulto e bambino.",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/PANTA_USA.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm14848432",
                            "field1": "GIACCA USA",
                            "field2": "felpa tempo libero ",
                            "field3": "con elastici in vita e ai polsi",
                            "field4": "con banda laterale",
                            "description": "GIACCA USA felpa tempo libero  con elastici in vita e ai polsi con banda laterale",
                            "grammage": null,
                            "price": "20.00",
                            "price_label": "€ 20,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Felpe e Pantaloni Lunghi",
                            "blueprint": {
                                "top": 3.1527364752207485,
                                "left": 26.789351851851848,
                                "width": 35.115848406546085,
                                "height": 19.27592239839571
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/GIACCA_USA_c7Fk56H.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_96pyP9f_mzkjrTy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "FELPA USA",
                                    "data": "https://www.massport.it/it/prodotti/abbigliamento/tute-rappresentanza/felpa-usa?search_query=usa&results=10#/15-colore-azzurro/12-misura-xxs"
                                },
                                {
                                    "type": "info",
                                    "title": "FELPA USA",
                                    "data": {
                                        "titolo": "",
                                        "testo": "La Felpa Usa rappresenta un salto di qualità rispetto al passate in termini di comfort, vestibilità e stile. Studiata per chi desidera indossarla in qualsiasi momento della giornata senza essere mai fuori luogo. Il nuovo tessuto in poliestere leggermente felpato, offre protezione dal freddo sia durante l’attività sportiva che nei momenti di relax e tempo libero. Il risultato?  Una felpa utilizzabile anche nei periodi primaverili, autunnali ed invernali.\n\nIl taglio raglan delle maniche, la vestibilità slim,  le costine elasticizzate in vita e ai polsi la rendono estremamente adattabile al corpo e comoda da indossare.\n\nLa chiusura con zip, resistente e robusta, con cursore in nylon, rende questa felpa  ancora più pratica da usare. Il design sobrio con finiture tono su tono e bande laterali creano uno look contemporaneo e inconfondibile.  Disponibile in taglie adulto e bambino.",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/GIACCA_USA.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm68480511",
                            "field1": "GIACCA NORWAY",
                            "field2": "felpa tempo libero",
                            "field3": "bicolore con elastici",
                            "field4": "in vita e ai polsi",
                            "description": "GIACCA NORWAY felpa tempo libero bicolore con elastici in vita e ai polsi",
                            "grammage": null,
                            "price": "20.00",
                            "price_label": "€ 20,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Felpe e Pantaloni Lunghi",
                            "blueprint": {
                                "top": 1.9253462943421953,
                                "left": 60.68184754521963,
                                "width": 37.12564599483205,
                                "height": 18.952924982375038
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/GIACCA_NORWAY_vI8JNSw.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_6vaYksZ_3ekorlv.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "GIACCA NORWAY",
                                    "data": "https://www.massport.it/it/prodotti/abbigliamento/tute-rappresentanza/giacca-norway#/13-misura-ys/111-colore-blu_giallo_fluo"
                                },
                                {
                                    "type": "info",
                                    "title": "GIACCA NORWAY",
                                    "data": {
                                        "titolo": "",
                                        "testo": "La Giacca Norway rappresenta un salto di qualità rispetto al passato in termini di comfort, vestibilità e stile.  Il nuovo tessuto in poliestere leggermente felpato, offre protezione dal freddo sia durante l’attività sportiva che nei momenti di relax e tempo libero. Il risultato?  Una felpa utilizzabile anche nei periodi primaverili, autunnali ed invernali e indossabile in qualsiasi momento della giornata.\n\nIl taglio giromaniche, la vestibilità slim,  le robuste costine elasticizzate in vita e ai polsi la rendono comoda da indossare,  estremamente adattabile al corpo e soprattutto resistente.\nLa solidità della zip, con cursore in nylon, rende questa felpa  ancora più pratica da usare. Il design b-colore con finiture  a contrasto creano uno look moderno e inconfondibile.  Disponibile in taglie adulto e bambino.\n\nTessuto di nuova generazione\n\nComfort e Resistenza",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/GIACCA_NORWAY.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm94271074",
                            "field1": "MAGLIA BAHIA",
                            "field2": "maglia da gara / allenamento",
                            "field3": "con tessuto tecnico a nido d'ape",
                            "field4": "doppia tonalità di colore",
                            "description": "MAGLIA BAHIA maglia da gara / allenamento con tessuto tecnico a nido d'ape doppia tonalità di colore",
                            "grammage": null,
                            "price": "11.00",
                            "price_label": "€ 11,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "T-Shirt, Polo e Bermuda",
                            "blueprint": {
                                "top": 18.72121192741713,
                                "left": 31.813845822566744,
                                "width": 37.21700043066323,
                                "height": 17.07953996945514
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/Maglia_bahia_Bd8oXu6.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_BN8rBje_GHyIgVW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "MAGLIA BAHIA",
                                    "data": "https://www.massport.it/it/sport/calcio/maglia-bahia#/13-misura-ys/23-colore-verde"
                                },
                                {
                                    "type": "info",
                                    "title": "MAGLIA BAHIA",
                                    "data": {
                                        "titolo": "",
                                        "testo": "La Bahia è una maglia di allenamento adatta a qualisiasi tipo di sport grazie al suo design sobrio e ricercato, con doppia tonalità di colore\n\nIdeale per affrontare le competizioni sportive, ma anche le fasi preparatorie e di allenamento. Il capo è realizzato con il nuovo poliestere 100% microforato, traspirante e delicato a contatto con la pelle, ma anche flessibile e robusto, capace quindi di resistere ai contrasti di gioco più duri e alle condizioni atmosferiche più sfavorevoli.\n\nIl taglio raglan agevola i movimenti naturali del corpo, mentre le rifiniture in mesh, posti ai lati della maglia, favoriscono la traspirabilità anche nelle fasi di gioco o di allenamento, più intense. Grazie alla vestibilità molto aderente, l’atleta si sente cucito adosso la propria divisa ottenendo il massimo comfort in qualsiasi condizione.",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/Maglia_bahia.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm43521582",
                            "field1": "PANTALONCINO SHOT",
                            "field2": "pantaloncino da gara / allenamento",
                            "field3": "monocolore",
                            "field4": "",
                            "description": "PANTALONCINO SHOT pantaloncino da gara / allenamento monocolore",
                            "grammage": null,
                            "price": "5.80",
                            "price_label": "€ 5,80",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Fitness e Palestra",
                            "blueprint": {
                                "top": 21.563589188399042,
                                "left": 68.62968346253228,
                                "width": 29.177810077519382,
                                "height": 14.172563225269098
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/PantaloncinoShotrosso_Oqog58H.jpg",
                                    "cropped": false
                                    },
                                    {
                                        "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_8ixSYYO_fMMjaIP.png",
                                        "cropped": true
                                        }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "PANTALONCINO SHOT",
                                    "data": "https://www.massport.it/it/sport/allenamento/short-shot#/8-misura-s/17-colore-rosso"
                                },
                                {
                                    "type": "info",
                                    "title": "PANTALONCINO SHOT",
                                    "data": {
                                        "titolo": "",
                                        "testo": "Il pantaloncino Shot è un pantaloncino pensato per affrontare sia la gara che l’allenamento nelle migliori condizioni di comfort. Il prodotto è realizzato in poliestere 100% ultraresistente. Tale tessuto gli permette di conservare la sua consistenza anche dopo innumerevoli lavaggi e dopo i contrasti di gioco più duri, ma allo stesso tempo lo fa risultare fresco e leggero a contatto con la pelle anche dopo ore di sforzi fisici.\n\nIl cavallo alto è stato studiato per assicurare la massima naturalezza e libertà di movimento, per far sì che l’ingombro della divisa non sia di minimo intralcio all’espressione del talento sportivo dell’atleta. Il design è sobrio, a tinta unita, e offre la massima versatilità: il pantaloncino Shot si presta quindi a essere indossato dagli sportivi di ogni età e disciplina. Perfetto da abbinare con le nostre divise gara o sotto una maglia da allenamento.",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/PantaloncinoShotrosso.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm78864122",
                            "field1": "CALZE LIGA ",
                            "field2": "calza da gara / allenamento",
                            "field3": "in poliestere, cotone e elastan",
                            "field4": "",
                            "description": "CALZE LIGA  calza da gara / allenamento in poliestere, cotone e elastan",
                            "grammage": null,
                            "price": "3.30",
                            "price_label": "€ 3,30",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Fitness e Palestra",
                            "blueprint": {
                                "top": 37.39046257341196,
                                "left": 4.47636735572782,
                                "width": 34.93313953488373,
                                "height": 16.4335451374138
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/Calze_Liga_x0hORev.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_fUMXmMG_tYCZZuY.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "CALZA DA CALCIO LIGA",
                                    "data": "https://www.massport.it/it/prodotti/intimo/calze/calza-da-calcio-liga?search_query=calze+liga&results=1#/48-misura-junior/3-colore-giallo"
                                },
                                {
                                    "type": "info",
                                    "title": "CALZA DA CALCIO LIGA",
                                    "data": {
                                        "titolo": "",
                                        "testo": "La calza Liga è una calza da gioco e da allenamento pensata per avvolgere perfettamente il piede e la gamba e conferire all’atleta il massimo comfort anche durante gli sforzi sportivi più intensi. Il prodotto è realizzato in poliestere con elastam, composizione che rappresenta il giusto compromesso tra leggerezza e consistenza, due qualità determinanti per chi si trova a praticare attività sportiva in ogni condizione.\n\nGrazie ai materiali resistenti, la calza Liga è in grado di sopportare innumerevoli lavaggi senza rinunciare alla sua morbidezza. L’elasticità del tessuto permette allo sportivo di esprimersi in campo con assoluta naturalezza e libertà di movimento, mentre il plantare in cotone attutisce l’impatto con la scarpa, ottimizzando le condizioni di comfort. La calza, ha infine un design sobrio e varsatile.",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/Calze_Liga.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm44688534",
                            "field1": "MAGLIA GAZELLE",
                            "field2": "maglia volley in cotone 100%",
                            "field3": "",
                            "field4": "",
                            "description": "MAGLIA GAZELLE maglia volley in cotone 100%",
                            "grammage": null,
                            "price": "3.90",
                            "price_label": "€ 3,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "T-Shirt, Polo e Bermuda",
                            "blueprint": {
                                "top": 35.775475493308605,
                                "left": 48.25764427217916,
                                "width": 51.74235572782084,
                                "height": 19.469720848008116
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/Magliagazelleazzurro_YB47SdK.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_gYgzQb4_IThd81E.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "MAGLIA GAZELLE VOLLEY",
                                    "data": "https://www.massport.it/it/sport/pallavolo/maglia-gazelle-volley#/15-colore-azzurro/9-misura-xl"
                                },
                                {
                                    "type": "info",
                                    "title": "MAGLIA GAZELLE VOLLEY",
                                    "data": {
                                        "titolo": "",
                                        "testo": "La giocatrice di volley ha bisogno di un abbigliamento comodo, leggero e resistente, che agevoli i movimenti e non ostacoli i recuperi palla più difficoltosi, garantendo la migliore prestazione possibile in campo.\n\nLa maglia Gazelle è realizzata in cotone elasticizzato, tessuto che non crea alcun attrito con il corpo anche dopo ore di gioco, non irritando quindi la pelle già stressata dal sudore. Il giromanica con costine elasticizzate e la perfetta vestibilità slim fit favoriscono il gesto atletico in modo semplice e armonico.\n\nInoltre l'abbigliamento sportivo MASS non trascura l’elemento estetico, per questo abbiamo scelto per questa maglia da pallavolo un design grintoso con colletto e inserti a contrasto di colore, per uno stile unico e distintivo. Disponibile in blu scuro, rosso, azzurro, grigio e nero.",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/Magliagazelleazzurro.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm59329487",
                            "field1": "GIACCA DOLOMITI",
                            "field2": "giaccone invernale con imbottitura",
                            "field3": "e cappuccio interno",
                            "field4": "",
                            "description": "GIACCA DOLOMITI giaccone invernale con imbottitura e cappuccio interno",
                            "grammage": null,
                            "price": "14.00",
                            "price_label": "€ 14,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Giacconi, K-Way e Piumini",
                            "blueprint": {
                                "top": 55.186245222747836,
                                "left": 32.53480806766429,
                                "width": 33.013012361743655,
                                "height": 17.14865464233777
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/GiaccaDolomiti_SG0Tbrw.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_N3Fn4XG_kJHd6UC.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "GIACCA GIUBBOTTO CON CAPPUCCIO DOLOMITI",
                                    "data": "https://www.massport.it/it/prodotti/abbigliamento/giacconi-k-way-piumini/giacca-giubbotto-con-cappuccio-dolomiti#/6-misura-l/26-colore-blu_giallo"
                                },
                                {
                                    "type": "info",
                                    "title": "GIACCA GIUBBOTTO CON CAPPUCCIO DOLOMITI",
                                    "data": {
                                        "titolo": "",
                                        "testo": "La giacca Dolomiti è un giaccone invernale da uomo confortevole e caldo realizzato in 100% poliestere waterproof, antipioggia e anti vento, per la massima protezione dall'umidità e dai colpi di freddo. Grazie ad un particolare trattamento superficiale, il tessuto di questo giaccone risulta leggero ma altamente impermeabile, quindi lo rende l'ideale per gli allenamenti outdoor in ogni condizione climatica.\n\nI microfori presenti sotto le braccia favoriscono la traspirazione mentre il pile sui polsi e sul collo della giacca dona ulteriore protezione dal freddo, senza il rischio di irritazioni alla pelle. Il taglio raglan delle maniche permette la massima libertà di movimento. Il cappuccio a scomparsa nel collo con copri zip ed inserti a contrasto creano un look vivace e distinto, ideale per i momenti correlati alle prestazioni sportive, ma anche per altre fasi della vita quotidiana in cui si desidera un abbigliamento comodo e casual.",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/GiaccaDolomiti.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm72512899",
                            "field1": "PIUMINO SESTRIERE",
                            "field2": "piumino unisex imbottito",
                            "field3": "e tessuto anti-vento e anti-acqua",
                            "field4": "vestibilità slim",
                            "description": "PIUMINO SESTRIERE piumino unisex imbottito e tessuto anti-vento e anti-acqua vestibilità slim",
                            "grammage": null,
                            "price": "14.00",
                            "price_label": "€ 14,00",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Giacconi, K-Way e Piumini",
                            "blueprint": {
                                "top": 55.25130703146611,
                                "left": 64.61309911082195,
                                "width": 33.013012361743655,
                                "height": 17.47396368592918
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/PiuminoSestrierenero_XqanF9q.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_lHFITmz_dTfYwcQ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "PIUMINO UNISEX SESTRIERE",
                                    "data": "https://www.massport.it/it/prodotti/abbigliamento/giacconi-k-way-piumini/piumino-unisex-sestriere#/7-misura-m/4-colore-nero"
                                },
                                {
                                    "type": "info",
                                    "title": "PIUMINO UNISEX SESTRIERE",
                                    "data": {
                                        "titolo": "",
                                        "testo": "Il piumino Sestriere è un giubbotto imbottito UNISEX indossabile sia da uomo che da donna ideato per donare comfort e protezione anche fuori dal campo, nei momenti di vita quotidiana. Progettato interamente in nylon, è leggero ma estremamente resistente ed isolante dal vento e dal freddo oltre che dalla pioggia e dall'umidà, grazie alla tecnologia waterproof e ad un particolare trattamento superficiale che rende il tessuto altamente impermeabile e permette alla pioggia di scivolare sul capo non appesantendolo.\n\nIl piumino ha una vestibilità aderente (slim fit), taglio giromanica con costine elasticizzate posizionate strategicamente sui polsi, in vita e al collo per una maggiore comodità e protezione dal vento. Il tessuto lucido gli conferisce un look deciso e unico. Disponibile in blu scuro e nero.",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/PiuminoSestrierenero.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm97339812",
                            "field1": "K-WAY ATOMIC",
                            "field2": "k-way anti-vento e anti-acqua",
                            "field3": "",
                            "field4": "",
                            "description": "K-WAY ATOMIC k-way anti-vento e anti-acqua",
                            "grammage": null,
                            "price": "7.50",
                            "price_label": "€ 7,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Abbigliamento",
                            "subcategory": "Giacconi, K-Way e Piumini",
                            "blueprint": {
                                "top": 70.98219383956183,
                                "left": 4.498869509043923,
                                "width": 39.044089147286826,
                                "height": 19.469720848008116
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/KWAYAtomicblu_OE8tNjM.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_6MlVvMV_ikgNkiV.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "K-WAY ATOMIC",
                                    "data": "https://www.massport.it/it/prodotti/abbigliamento/giacconi-k-way-piumini/k-way-atomic#/9-misura-xl/1-colore-blu"
                                },
                                {
                                    "type": "info",
                                    "title": "K-WAY ATOMIC",
                                    "data": {
                                        "titolo": "",
                                        "testo": "Il k-way Atomic è una giacca antipioggia pensata per l’atleta che si allena in outdoor anche in condizioni atmosferiche avverse. Il prodotto è realizzato in 100% nylon ultra resistente, tessuto che, grazie alla spalmatura interna, protegge sia dalla pioggia che dal vento.\n\nI lacci regolabili ai lati e il collo a V aumentano il comfort ed assicurano allo sportivo la massima libertà di movimento in ogni fase della preparazione atletica. Il design è sobrio e versatile, quindi il k-way Atomic si presta a molteplici abbinamenti e si adatta a diversi momenti della vita quotidiana: ad esempio può essere un ottimo alleato in viaggio.\n\nNB: per i materiali utilizzati, purtroppo sui k-way non è possibile effettuare stampe di alcun tipo.",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/KWAYAtomicblu.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm11118947",
                            "field1": "ZUCCOTTO BICOLORE",
                            "field2": "zuccotto in pile e acrilico",
                            "field3": "reversibile",
                            "field4": "",
                            "description": "ZUCCOTTO BICOLORE zuccotto in pile e acrilico reversibile",
                            "grammage": null,
                            "price": "3.80",
                            "price_label": "€ 3,80",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Borse e Accessori",
                            "subcategory": "Accessori",
                            "blueprint": {
                                "top": 72.66178040286933,
                                "left": 42.59366925064599,
                                "width": 16.47954349698536,
                                "height": 17.983932734313026
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/zuccottobicoloregialloblu_CFnCjML.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_VnfkuYg_ZYZqR7q.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "ZUCCOTTO BICOLORE INTERNO IN PILE",
                                    "data": "https://www.massport.it/it/prodotti/borse-e-accessori/accessori/zuccotto-bicolore-interno-in-pile#/2-misura-unica/26-colore-blu_giallo"
                                },
                                {
                                    "type": "info",
                                    "title": "ZUCCOTTO BICOLORE INTERNO IN PILE",
                                    "data": {
                                        "titolo": "",
                                        "testo": "Questo cappellino bicolore è un berretto invernale pensato per donare protezione e comfort durante l'allenamento all’aperto e gli sport outdoor durante i mesi più freddi. Protegge l’atleta dalle intemperie e dall'umidità. Adatto per adulti e ragazzi.\n\nIl cappello è double face, realizzato in multimateriale reversibile, con interno in pile ed esterno in acrilico, consente di avere un colore diverso per ciascuno dei due lati. Adatto per l'attività sportiva e per tutti gli altri momenti della vita quotidiana in cui si necessita della massima protezione dal freddo.",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/zuccottobicoloregialloblu.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm98260229",
                            "field1": "SCIARPA PILE",
                            "field2": "sciarpa in pile",
                            "field3": "monocolore",
                            "field4": "",
                            "description": "SCIARPA PILE sciarpa in pile monocolore",
                            "grammage": null,
                            "price": "3.30",
                            "price_label": "€ 3,30",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Borse e Accessori",
                            "subcategory": "Accessori",
                            "blueprint": {
                                "top": 67.68762019615099,
                                "left": 55.84006244616709,
                                "width": 25.06686046511628,
                                "height": 22.8288939746231
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/sciarpafilegrigio_d44Fsyc.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_w9uU9Sg_JdkLfja.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "SCIARPA PILE SCI",
                                    "data": "https://www.massport.it/it/prodotti/borse-e-accessori/accessori/sciarpa-pile-sci#/2-misura-unica/14-colore-grigio"
                                },
                                {
                                    "type": "info",
                                    "title": "SCIARPA PILE SCI",
                                    "data": {
                                        "titolo": "",
                                        "testo": "Questa sciarpa in pile, leggera, soffice e avvolgente, protegge l’atleta nelle fasi collaterali alle competizioni sportive, nei momenti di pausa, in panchina e in tutti quei momenti della vita quotidiana in cui necessita della massima protezione dal freddo, a completamento di un look casual.\n\nIl design semplice, a tinta unita con logo a contrasto, è sobrio e versatile. Disponibile in blu scuro, grigio chiaro e nero, è adatta per adulti e ragazzi. La sciarpa in pile riscalda senza causare irritazioni alla pelle, nemmeno alla più sensibile.",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/sciarpafilegrigio.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm53363886",
                            "field1": "GUANTI LANA",
                            "field2": "guanto in acrilico",
                            "field3": "monocolore",
                            "field4": "",
                            "description": "GUANTI LANA guanto in acrilico monocolore",
                            "grammage": null,
                            "price": "3.50",
                            "price_label": "€ 3,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Borse e Accessori",
                            "subcategory": "Accessori",
                            "blueprint": {
                                "top": 71.82198712121559,
                                "left": 77.85648148148147,
                                "width": 20.499138673557283,
                                "height": 18.694527049558506
                            },
                            "images": [
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/guanto-lana_xtzW8ir.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/products/cropped_image_HiHnVWm_dZy9Ln5.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "GUANTO LANA",
                                    "data": "https://www.massport.it/it/prodotti/borse-e-accessori/accessori/guanto-lana#/16-misura-senior/4-colore-nero"
                                },
                                {
                                    "type": "info",
                                    "title": "GUANTO LANA",
                                    "data": {
                                        "titolo": "",
                                        "testo": "Questi guanti sono l'ideale per mantenere al caldo le mani durante la gara, l’allenamento outdoor e nei momenti di pausa, quando il freddo si avverte di più. Sono realizzati in acrilico, un materiale leggero ma allo stesso tempo capace di offrire un’ottima protezione da freddo e umidità.\n\nLa coppia di guanti, dal design sobrio e distinto, a tinta unita con logo ricamato a contrasto, è disponibile in due misure (adulto e bambino) e in tre varianti di colore (blu, azzurro e nero), che si prestano agli abbinamenti più vari, non solo durante l'attività sportiva ma anche nei momenti della vita quotidiana nei periodi più freddi dell'anno.",
                                        "img": [
                                            "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/images/guanto-lana.jpg"
                                        ]
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 3,
                    "image_file": "https://r1-it.storage.cloud.it/interactive/85xtoJIJQN/pages/fine-pagina_massport_MKJAKwC.png",
                    "products": []
                }
            ]
        }
    }

}

const lefletSwitch = () => {
    return leaflets.massport;
}

export const serverResponse = lefletSwitch();